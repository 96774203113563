
import header from '../../res/headerlogo.png';
import userDafault from '../../res/userdefault@80.jpg';
import defaultLawerHeader from '../../res/headerlogo@2x.png';
import { Image  } from 'antd';

const CompMsg = ({msg}) =>{
    // console.log(localStorage.getItem('lawyer_img'));
    // console.log( (localStorage.getItem('lawyer_img')!=undefined && localStorage.getItem('lawyer_img').length!=0) );
    const img = (localStorage.getItem('selectLawyerImg')!=undefined && localStorage.getItem('selectLawyerImg').length!=0)?localStorage.getItem('selectLawyerImg'):defaultLawerHeader;
    // console.log(img);
    return(
        <div style={{marginTop:"14px",display:"flex"}}>
            <div style={{marginLeft:"16px",display:"inline-block"}}>
                <Image src={img} style={{width:"40px",height:"40px"}}></Image>
            </div>
        
            <div style={{display:"inline",marginLeft:"6px",marginRight:"53px",paddingTop:"12px",paddingLeft:"10px",paddingRight:"10px",backgroundColor:"#FFFFFF",borderRadius:"4px"}}>
                <p style={{fontSize:"14px",fontFamily:"PingFang SC",color:"#333333",fontWeight:"400"}}>
                {msg} 
                </p>
            </div>
        </div>
    );
}
const VisitMsg = ({msg})=>{
    return(
        <div style={{marginTop:"14px",display:"flex",justifyContent:"flex-end"}}>
        <div style={{display:"inline",marginRight:"6px",marginLeft:"53px",paddingTop:"12px",paddingLeft:"10px",paddingRight:"10px",backgroundColor:"#FFFFFF",borderRadius:"4px"}}>
            <p style={{fontSize:"14px",fontFamily:"PingFang SC",color:"#333333",fontWeight:"400"}}>
            {msg} 
            </p>
        </div>
        <div style={{marginRight:"16px",display:"inline-block"}}>
            <img src={userDafault} style={{width:"40px",height:"40px"}}></img>
        </div>
    </div>
    );
}
export const MessageItem = ({msg,senderByMe})=>{
    console.log("====》",msg)


    return(
        senderByMe?<CompMsg msg={msg}></CompMsg>:<VisitMsg msg={msg}></VisitMsg>
    );
}