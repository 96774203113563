
// import {LeftOutlined } from '@ant-design/icons';
import {LeftOutlined,MessageOutlined } from '@ant-design/icons';
import banner from '../res/new_page_banner@2x.jpg';

import { Button,Modal,Divider,Drawer,Space } from 'antd';
import {useSelector,useDispatch} from 'react-redux';

import fangchan from '../res/ic_fangchan@2x.png';
import hetong from '../res/ic_hetong@2x.png';
import jiakuan from '../res/ic_jiakuan@2x.png';
import jiaotong from '../res/ic_jiaotong@2x.png';
import jiating from '../res/ic_jiating@2x.png';
import jingji from '../res/ic_jingji@2x.png';
import laodong from '../res/ic_laodong@2x.png';
import xingshi from '../res/ic_xingshi@2x.png' ;

import btnIcon from '../res/icon.png';

import './home.css';
import {Header} from './home/Header';
import {AdPage} from './home/adpage'
import {SecurityHint} from './home/security-hint';
import {FooterBar} from './home/footer';
import {Messages} from './home/message';
import {DialComfirm} from './home/dial-comfirm';
import {useState,useEffect} from 'react';
import {MessageItem} from './home/message-item';
import {MessageWechatCard} from './home/message-item-card';
import {GetLawyers} from '../service/get_lawyers';
import {testPost,testPostLocalhost} from '../service/test';
import {ReducerType} from '../store/reducer';
import {initInfo} from '../service/init-info';

import {DialConfirm} from './dial-comfirm';
import { useHistory } from "react-router-dom";

import {PopChat} from './pop-chat';

export const NewHome = ()=> {
    console.log(localStorage.getItem('lawyers'));
    const initLawyerData = localStorage.getItem('lawyers')===null?"[]":localStorage.getItem('lawyers');
    const [Lawyers,setLawyers] = useState(JSON.parse( initLawyerData ));
    const [drawVisible, setDrawVisible] = useState(false);
    const showConfirm = useSelector(state => state.uIShowDialConfirm);
    const dispatch = useDispatch();
    let history = useHistory();

    const Keeptime = 36000;
    let nowTime = Math.floor(Date.now() / 1000);
    const expire = nowTime > Number(localStorage.getItem('updateTime')) + Keeptime;

    useEffect(()=>{
        GetLawyers((resp)=>{
            console.log("====>",resp.data.data.data);
            localStorage.setItem('lawyers', JSON.stringify(resp.data.data.data));
            setLawyers(resp.data.data.data);
        });
        // testPost((resp)=>{
        //     console.log("=====>",resp);
        // });
        // testPostLocalhost((resp)=>{
        //     console.log("====>",resp);
        // });

        if(nowTime > Number(localStorage.getItem('updateTime')) + Keeptime){
            //缓存过久，需要更新
            console.log("缓存时间太久，需要更新");
            initInfo((resp)=>{
                console.log("初始化接口的回调");
                // setLawerName(resp.data.data.lawer_name);
                localStorage.setItem('lawyerName',resp.data.data.lawer_name);
                // setWechat(resp.data.data.wechat);
                localStorage.setItem('wechat',resp.data.data.wechat);
                localStorage.setItem('lawyer_img',resp.data.data.lawer_img);
                // localStorage.setItem('selectLawyerImg',resp.data.data.lawer_img)
                localStorage.setItem('lawyer_id',resp.data.data.manager_id)
                // console.log("====>",LawerName,resp.data.data.lawer_name);
                localStorage.setItem('updateTime',Math.floor(Date.now() / 1000))
                localStorage.setItem('lawyer_phone',resp.data.data.phone);

                localStorage.setItem("selectLawyerName",resp.data.data.lawer_name);
                localStorage.setItem("selectLawyerImg", resp.data.data.lawer_img);
                localStorage.setItem("selectLawyerUid",resp.data.data.manager_id);
                
            });
        }


        setTimeout(()=>{
            
          setDrawVisible(true);

        }, 1000);

    },[]);


    const dialNow = (phone,work_status)=>{
        if(work_status ===0 ){
            console.log("x_phone",phone);
            window.open('tel:'+ phone );
        }else{
            window.open('tel:'+ localStorage.getItem("lawyer_phone") );
        }
        // console.log("x_phone",phone);
        // window.open('tel:'+ phone );
    }
   
    const evtShowConfirm = ()=>{
        console.log("显示收费弹窗");
        dispatch({type:ReducerType.UIShowDialConfirm,data:true});
    }

    const dialDefaultLawyer = (e)=>{
        console.log(  localStorage.getItem('lawyer_phone') );
        window.open('tel:'+ localStorage.getItem('lawyer_phone'));
    }


    const gotoMainPage = (lawyerName, lawyerImg, lawyerUid, lawyerXPhone,work_status)=>{
        if(work_status===0){
            console.log("=====>跳转到老的主页",lawyerName, lawyerImg, lawyerUid, lawyerXPhone);
            localStorage.setItem("selectLawyerName",lawyerName);
            localStorage.setItem("selectLawyerImg", lawyerImg);
            localStorage.setItem("selectLawyerUid",lawyerUid);
            localStorage.setItem("selectLawyerPhone",lawyerXPhone);
            history.push('/index');
        }else{
            // localStorage.setItem("selectLawyerName", localStorage.getItem("lawyerName") );
            // localStorage.setItem("selectLawyerImg", localStorage.getItem("lawyer_img") );
            // localStorage.setItem("selectLawyerUid",localStorage.getItem("lawyer_id") );
            localStorage.setItem("selectLawyerName", lawyerName );
            localStorage.setItem("selectLawyerImg", lawyerImg );
            localStorage.setItem("selectLawyerUid", lawyerUid );
            localStorage.setItem("selectLawyerPhone",localStorage.getItem("lawyer_phone") );
            history.push('/index');
        }
    }

    const onClose = () => {
        console.log("关闭");
       setDrawVisible(false);
      };

    return(
        <div>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginTop:"12px"}}>
                <div style={{float:"left",position:"absolute",left:"15px",fontWeight:"bold",fontSize:"18px"}}><LeftOutlined></LeftOutlined></div>
                <div style={{fontWeight:"bold",fontSize:"18px"}}>律师侠电话咨询</div>
            </div>
            <div style={{margin:"20px",position:"relative"}}>
                <img src={banner} style={{width:"100%",height:"100%",position:"relative",left:"0px", top:"0px"}}></img>
                <Button type="text" shape="round"  size="large" onClick={dialDefaultLawyer}
                    style={{position:"absolute", left:"20px",fontSize:"18px", bottom:"20px",color:"white",backgroundColor:"#FF8600"}}>
                        立即咨询 8元/分钟
                </Button>
            </div>
            <div style={{marginLeft:"20px"}}>
                <div style={{fontSize:"16px",fontWeight:"bold"}}>按类型咨询</div>
                <div style={{display:"grid",gridTemplateColumns:"repeat(4,25%)", marginTop:"18px"}}>
                    <div >
                        <img src={jiating} style={{height:"44px",width:"44px"}} onClick={evtShowConfirm}></img>
                        <div style={{marginTop:"6px",fontSize:"13px",fontWeight:"bold"}}>家庭婚姻</div>
                    </div>
                    <div>
                        <img src={laodong} style={{height:"44px",width:"44px"}} onClick={evtShowConfirm}></img>
                        <div style={{marginTop:"6px",fontSize:"13px",fontWeight:"bold"}}>劳动纠纷</div>
                    </div>
                    <div>
                        <img src={jiaotong} style={{height:"44px",width:"44px"}} onClick={evtShowConfirm}></img>
                        <div style={{marginTop:"6px",fontSize:"13px",fontWeight:"bold"}}>交通事故</div>
                    </div>
                    <div>
                        <img src={xingshi} style={{height:"44px",width:"44px"}} onClick={evtShowConfirm}></img>
                        <div style={{marginTop:"6px",fontSize:"13px",fontWeight:"bold"}}>刑事辩护</div>
                    </div>
                    <div style={{marginTop:"18px"}}>
                        <img src={jiakuan} style={{height:"44px",width:"44px"}} onClick={evtShowConfirm}></img>
                        <div style={{marginTop:"6px",fontSize:"13px",fontWeight:"bold"}}>借款借贷</div>
                    </div>
                    <div style={{marginTop:"18px"}}>
                        <img src={hetong} style={{height:"44px",width:"44px"}} onClick={evtShowConfirm}></img>
                        <div style={{marginTop:"6px",fontSize:"13px",fontWeight:"bold"}}>合同纠纷</div>
                    </div>
                    <div style={{marginTop:"18px"}}>
                        <img src={fangchan} style={{height:"44px",width:"44px"}} onClick={evtShowConfirm}></img>
                        <div style={{marginTop:"6px",fontSize:"13px",fontWeight:"bold"}}>房产纠纷</div>
                    </div>
                    <div style={{marginTop:"18px"}}>
                        <img src={jingji} style={{height:"44px",width:"44px"}} onClick={evtShowConfirm}></img>
                        <div style={{marginTop:"6px",fontSize:"13px",fontWeight:"bold"}}>经济纠纷</div>
                    </div>
                </div>

                <div style={{fontSize:"16px", fontWeight:"bold", marginTop:"30px"}}>
                    法律服务团队
                </div>
                <div style={{marginTop:"16px"}}>
                {Lawyers.map((item,index)=>{
                            return(
                                <div>
                                <div style={{display:"flex",justifyContent:"flex-start",paddingBottom:"17px"}}>
                                    <img src={item.img} style={{width:"81px",height:"101px"}}></img>
                                    <div style={{marginLeft:"14px",marginTop:"1px"}}>
                                        <div style={{fontSize:"14px",fontWeight:"bold",marginTop:"0px"}}> {item.lawer_name}</div>
                                        <div style={{fontSize:"12px",fontWeight:"bold", marginTop:"2px"}}>擅长:<span style={{marginLeft:"4px"}}>{item.expert_fields}</span> </div>
                                        <div style={{marginTop:"2px"}}>帮助人数:{item.dial_count}人</div>
                                        <div>
                                            <Button type="text" onClick={()=>dialNow(item.x_phone,item.work_status)}
                                                style={{fontSize:"12px",color:"white",backgroundColor:"#FF8600"}}>
                                                    立即咨询 8元/分钟
                                            </Button>
                                            <Button type="text" onClick={()=>{gotoMainPage(item.lawer_name, item.img, item.lawer_id,item.x_phone,item.work_status)}}
                                             style={{marginLeft:"14px",}}  icon={<MessageOutlined style={{color:"#FF8600",fontSize:"22px",fontWeight:"bold",border:""}}/>}>
                                            </Button>
                                            <div></div>
                                        </div>
                                        
                                    </div>

                                </div>
                                <Divider style={{width:"100%",marginTop:"1px",marginBottom:"17px"}}></Divider>

                                </div>
                            )
                        })}
                    <div>
                        
                    </div>
                </div>
            </div>
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",paddingBottom:"10px"}}>
                <div style={{alignItems:"center",}}>长沙市天律网络科技有限公司</div>
            </div>
            
            <Modal
                title={null}
                visible={showConfirm}
                footer={null}
                width="275px"
                centered
                closable={false}
                style={{paddingLeft:"0px"}}
            >
                <DialConfirm></DialConfirm>
            </Modal>

            <Drawer
                placement="bottom"
                width="100%"
                height={550}
                visible={drawVisible}
                bodyStyle={{padding:"0px"}}
                // style={{borderRadius:"10px"}}
                closable={false}
            >
                <PopChat closeDraw={onClose}></PopChat>
            </Drawer>

        </div>
    );

}
