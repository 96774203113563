
import { Button,Radio,Divider,Image  } from 'antd';
import header from '../../res/headerlogo.png';
import { useHistory } from "react-router-dom";
import {useSelector,useDispatch} from 'react-redux';
import {ReducerType} from '../../store/reducer';
import defaultLawerHeader from '../../res/headerlogo@2x.png';
import './home.css';
//买一个预付费套餐。
export const MessageChoice = ({onMsgContinue})=>{
    let history = useHistory();
    const verifiedMobile = useSelector(state => state.dataVerifiedMobile);
    const dispatch = useDispatch();
    const img = (localStorage.getItem('selectLawyerImg')!=undefined && localStorage.getItem('selectLawyerImg').length!=0)?localStorage.getItem('selectLawyerImg'):defaultLawerHeader;
    const onContinue = ()=>{
        if(onMsgContinue!==undefined){
            onMsgContinue();
        }
    }
    const jumpCall = ()=>{
        window.open('tel:'+localStorage.getItem('selectLawyerPhone'));
    }
    return(
    <div style={{marginTop:"14px",display:"flex"}}>
        <div style={{marginLeft:"16px",display:"inline-block"}}>
            <Image  src={img} style={{width:"40px",height:"40px"}}></Image >
        </div>
        <div style={{display:"inline",marginLeft:"6px",marginRight:"53px",paddingTop:"12px",backgroundColor:"#FFFFFF",borderRadius:"4px"}}>

            <div style={{color:"#333333",fontSize:"14px",paddingLeft:"10px",paddingRight:"10px",
                    fontFamily: "PingFangSC-Regular, PingFang SC",
                    marginTop:"6px",marginLeft:"10px",marginRight:"10px"}}>
                        推荐您电话咨询,方便了解情况更快为您解答,咨询费8元/分钟,先解答后付费,号码{localStorage.getItem('selectLawyerPhone')},是否需要电话咨询?
               </div>

            <div style={{height:"32px",display:"flex",alignItems:"center",justifyContent:"center",marginTop:"12px",marginBottom:"12px"}}>
                    <div style={{backgroundColor:"#FF8600",fontSize:"14px",border:"0",
                        height:"100%",borderRadius:"4px",
                        width:"110px",display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center",
                        color:"White",lineHeight:"20px"}}
                        onClick={onContinue}
                        >
                            在线咨询</div>
                    <div style={{backgroundColor:"#FF8600",fontSize:"14px",border:"0",
                        height:"100%",borderRadius:"4px",marginLeft:"10px",
                        color:"white",
                        width:"110px",display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center",
                        lineHeight:"20px"}}
                        onClick={jumpCall}
                        >
                            电话咨询</div>
            </div>

        </div>
        
    </div>);
}