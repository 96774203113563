
import { Button,Image} from 'antd';
import header from '../../res/headerlogo.png';
import { useHistory } from "react-router-dom";
import {useSelector,useDispatch} from 'react-redux';
import {ReducerType} from '../../store/reducer';
import defaultLawerHeader from '../../res/headerlogo@2x.png';
import './home.css';

//买一个预付费套餐。
export const MessageBuyPack = ()=>{
    let history = useHistory();
    // const verifiedMobile = useSelector(state => state.dataVerifiedMobile);
    const dispatch = useDispatch();
    const img = (localStorage.getItem('selectLawyerImg')!=undefined && localStorage.getItem('selectLawyerImg').length!=0)?localStorage.getItem('selectLawyerImg'):defaultLawerHeader;

    const jumpBuy = ()=>{
        if(localStorage.getItem('phone')===undefined || localStorage.getItem('phone').length===0){//未登录手机号，需要登录。
            dispatch({type:ReducerType.UIShowInputMobile,data:true});
        }else{
            // console.log(verifiedMobile);
            history.push("/prebuy");
        }
    }
    return(
    <div style={{marginTop:"14px",display:"flex"}}>
        <div style={{marginLeft:"16px",display:"inline-block"}}>
            <Image src={img} style={{width:"40px",height:"40px"}}></Image>
        </div>
        <div style={{display:"inline",marginLeft:"6px",marginRight:"53px",paddingTop:"12px",paddingBottom:"12px",paddingLeft:"10px",paddingRight:"10px",backgroundColor:"#FFFFFF",borderRadius:"4px"}}>
            <div style={{color:"#FF5200",fontSize:"14px",marginLeft:"10px",
                fontFamily:"PingFang SC",
                marginRight:"10px"}}>在线咨询：￥68元/60分钟</div>
            <div style={{color:"#333333",fontSize:"14px",marginTop:"6px",marginLeft:"10px",marginRight:"10px"}}>极速响应，平均30秒钟回复</div>
            <div style={{color:"#333333",fontSize:"14px",marginTop:"6px",marginLeft:"10px",marginRight:"10px"}}>即时沟通，有效时间内不限对话次数</div>
            <div style={{color:"#333333",fontSize:"14px",marginTop:"6px",marginLeft:"10px",marginRight:"10px"}}>问题解决率99%，不满意可退款</div>
            <div style={{marginRight:"14px", marginLeft:"14px"}}>
                <Button block style={{borderRadius:"4px",backgroundColor:"#FF8600",fontSize:"14px",border:"0",
                    marginTop:"14px",
                    height:"34px", 
                    color:"white",paddingLeft:"21px",paddingRight:"21px",paddingTop:"7px",paddingBottom:"7px",lineHeight:"20px"}}
                    onClick={jumpBuy}
                    >
                        立即付费并使用</Button>
            </div>
            
        </div>
        
    </div>);
}








//买一个预付费套餐。
export const MessageBuyPackV2 = ()=>{
    let history = useHistory();
    // const verifiedMobile = useSelector(state => state.dataVerifiedMobile);
    const dispatch = useDispatch();
    const img = (localStorage.getItem('selectLawyerImg')!=undefined && localStorage.getItem('selectLawyerImg').length!=0)?localStorage.getItem('selectLawyerImg'):defaultLawerHeader;

    const jumpBuy = ()=>{
        if(localStorage.getItem('phone')===undefined || localStorage.getItem('phone').length===0){//未登录手机号，需要登录。
            dispatch({type:ReducerType.UIShowInputMobile,data:true});
        }else{
            // console.log(verifiedMobile);
            history.push("/prebuy");
        }
    }
    return(
    <div style={{marginTop:"14px",display:"flex"}}>
        <div style={{marginLeft:"16px",display:"inline-block"}}>
            <img src={img} style={{width:"40px",height:"40px"}}></img>
        </div>
        <div style={{display:"inline",marginLeft:"6px",marginRight:"53px",paddingTop:"12px",paddingBottom:"12px",paddingLeft:"10px",paddingRight:"10px",backgroundColor:"#FFFFFF",borderRadius:"4px"}}>

            <div style={{color:"#333333",fontSize:"14px",marginTop:"6px",marginLeft:"10px",marginRight:"10px"}}>
            你的问题我已经大概了解，点击下方按钮付费后我为你详细解答
            </div>
            
            <div style={{marginRight:"14px", marginLeft:"14px"}}>
                <Button block style={{borderRadius:"4px",backgroundColor:"#FF8600",fontSize:"14px",border:"0",
                    marginTop:"14px",
                    height:"34px", 
                    color:"white",paddingLeft:"21px",paddingRight:"21px",paddingTop:"7px",paddingBottom:"7px",lineHeight:"20px"}}
                    onClick={jumpBuy}
                    >
                        立即付费并使用</Button>
            </div>
            
        </div>
        
    </div>);
}