
import { Button,Modal } from 'antd';
import { Input } from 'antd';
import {useState} from 'react';
import { Divider } from 'antd';
import {useSelector,useDispatch} from 'react-redux';
import {ReducerType} from '../store/reducer';

import {CloseOutlined  } from '@ant-design/icons';


export const DialConfirm = ()=>{
    const dataPhone = useSelector(state => state.dataPhone);
    const dispatch = useDispatch();
    const handleCancel=(e)=>{
        console.log("关闭confirm");
        dispatch({type:ReducerType.UIShowDialConfirm,data:false});
    }
    const handleDial = (e)=>{
        console.log(  localStorage.getItem('lawyer_phone') );
        window.open('tel:'+ localStorage.getItem('lawyer_phone'));
    }
    return(
    <div style={{textAlign:"center"}}>
        <div style={{fontSize:"18px",fontFamily:"PingFang SC",fontWeight:"400",color:"#333333"}}>温馨提示</div>
        <div style={{color:"#333333",fontSize:"16px",fontWeight:"normal",marginTop:"8px",marginLeft:"24px",marginRight:"24px"}}>向我们电话咨询
先咨询后付费，8元/分钟</div>
        {/* <hr style={{color:"#EEEEEE",}}></hr> */}
        <Divider style={{width:"100%",marginTop:"12px",marginBottom:"12px"}}></Divider>
        <div style={{display:"grid",gridTemplateColumns:"repeat(2, 50%)"}}>
        <Button  type="text" style={{color:"#FF8600",fontSize:"16px",fontWeight:"normal"}} onClick={handleDial}>立即咨询</Button>   
        <Button  type="text" style={{color:"#FF8600",fontSize:"16px",fontWeight:"normal"}} onClick={handleCancel}>取消</Button>   
        </div>

    </div>);
}
