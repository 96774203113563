import Store from '../store/store';
import {ReducerType} from '../store/reducer';
import {Api} from './api-define';
import RequestHooksBack from '../utils/http_hooks_back';

export const GetLawyers =(callback)=>{
    const state = Store.getState();
    let baseUrl = Api.rootPath;
    if (window.location.protocol === "http:") {
        baseUrl = Api.rootPathUnsafe;
    }
    RequestHooksBack({
        url: baseUrl + Api.GetLawyers,
        method: "GET",
    },  (Response)=>{
        console.log("===>",Response,Response.data.data);
            if(callback!==undefined){
                callback(Response);
            }
        }
    );
}
