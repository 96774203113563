import './home.css';
import {Header} from './home/Header';

import {CloseOutlined } from '@ant-design/icons';

import {AdPage} from './home/adpage'
import {SecurityHint} from './home/security-hint';
import {FooterBarUC} from './home/footer-uc';
import {Messages} from './home/message';
import {DialComfirm} from './home/dial-comfirm';
import {useState,useEffect} from 'react';
import {MessageItem} from './home/message-item';
import {MessageWechatCard} from './home/message-item-card';
import {MessageBuyPack,MessageBuyPackV2} from './home/message-buy-pack';
import {useSelector} from 'react-redux';
import {initInfo} from '../service/init-info';
import Store from '../store/store';
import {ReducerType} from '../store/reducer';
import {MessageChoice} from './home/message-choice';
import {parseQueryString,parseKey,parseHref} from '../utils/common';

import { useHistory } from "react-router-dom";


/******
 * localstorage记录最近调用getInitInfo更新配置信息的时间。最近的聊天信息列表。
 *  如果更新时间不超过10分钟，则不重复请求获取初始配置信息。
 *  否则，调用接口获取律师初始配置，。
 * app初始化
 *******/
export const PopChat = ({closeDraw})=>{
    const Keeptime = 36000;
    // const msgKey = 'msgList'+localStorage.getItem("selectLawyerUid");
    const msgKey = 'msgList';
    console.log(msgKey);
    const [LawerName,setLawerName] = useState(localStorage.getItem('lawyerName'));
    const [wechat,setWechat] = useState(localStorage.getItem('wechat')!=undefined?localStorage.getItem('wechat')===undefined:"");
    console.log(localStorage.getItem( msgKey ));
    console.log(JSON.parse(localStorage.getItem( msgKey ))!=undefined );
    // const [MsgList,setMsgList] = useState(JSON.parse(localStorage.getItem('msgList'))!=undefined?[...JSON.parse(localStorage.getItem('msgList'))]:[]);
    let nowTime = Math.floor(Date.now() / 1000);

    let history = useHistory();

    const expire = nowTime > Number(localStorage.getItem('updateTime')) + Keeptime;
    const msg = localStorage.getItem(msgKey)!=undefined ? JSON.parse(localStorage.getItem( msgKey )): [];
    const [MsgList,setMsgList] = useState( expire?[]:msg );
    console.log(MsgList);
    // console.log(msg);
    
    const updateMsgList = (data)=>{
        localStorage.setItem( msgKey, JSON.stringify(data));
        setMsgList(data);
    }
    useEffect(()=>{
        let channelName = parseHref("aff")
        console.log(channelName);
        localStorage.setItem("user_channel",channelName);
        // if(nowTime > Number(localStorage.getItem('updateTime')) + Keeptime){
            //缓存过久，需要更新
            console.log("缓存时间太久，需要更新");
          
            if(MsgList.length<=2){
                insertResponseMsgWithAutoDelay("你好","chat",true,()=>{
                    insertResponseMsgWithAutoDelay("是想解决什么问题呢？","chat",true,()=>{
                        insertResponseMsgWithAutoDelay("请说一下事情的经过，了解情况后为你解答","chat",true);
                    });
                });
                
            }

        // }
       
    },[]);
    


   const scrollBottom=()=>{
        console.log("滚动到底部");
        // document.getElementById('scrollable-root').scrollIntoView({ behavior: "smooth", block: "end" });
        setTimeout(() => {  
            let ele = document.getElementById("scrollable-root");
            ele.scrollTop = ele.scrollHeight ;
         }, 10);
 
   }
   /********
    * 系统自动回复的消息调用此方法发送。
    * 3个字显示1秒，最多10秒
    *********/
    const insertResponseMsgWithAutoDelay = (msgBody, msgType, sendBySystem,cb) => {
        console.log("insertResponseMsgWithAutoDelay====>",msgBody);
        let durationLen = (msgBody.length/3) > 3?3:(msgBody.length/3);
        if(msgType === "choice"){
            durationLen = 3;
        }
        console.log("insertResponseMsgWithAutoDelay===>",msgBody,msgType,durationLen);

        // document.getElementById('inputing-normal').style.visibility = 'hidden';
        // document.getElementById('inputing-hint').style.visibility = 'visible';
        // document.getElementById('inputing-hint').style.display = 'flex';

        //flex

        setTimeout(()=>{
            
            insertMsg(msgBody,msgType,sendBySystem);
            // if(document.getElementById('inputing-hint')!==undefined && document.getElementById('inputing-hint')!==null){
            //     document.getElementById('inputing-hint').style.visibility = 'hidden';
            //     document.getElementById('inputing-hint').style.display = 'none';
            // }
            // if(document.getElementById('inputing-normal')!==undefined && document.getElementById('inputing-normal')!==null){
            //     document.getElementById('inputing-normal').style.visibility = 'visible';
            // }

            if(cb!==undefined){
                cb();
            }

        },durationLen * 1000);
    }
   const insertMsg = (msgBody, msgType, sendBySystem)=>{
        let tmp = MsgList;
        tmp.push({
            content: msgBody,
            msgType: msgType,
            senderByMe:sendBySystem,
        });
        updateMsgList([...tmp]);
        scrollBottom();
   }
    const showBuy = ()=>{
        insertMsg("","online",true);
    }
    const checkUserHistory=()=>{
        let count  = 0;
        for(let idx = 0;idx < MsgList.length;idx++){
            if(MsgList[idx].senderByMe==false){ //存在我发过的记录
                console.log("存在用户发送的记录");
                count = count + 1;
            }
        }
        console.log("没有用户发送的记录");
        return count;
    }
    //检查是否有发送过消息，如果有，则自动给回复“电话咨询满意后再付费，请放心咨询”。如果没有，则说明用户是第一次发送消息，等待，并发送选项卡。
    const sendMsg = (msg)=>{
        console.log("发送消息",msg);
        let msgCount = checkUserHistory();
        insertMsg(msg,"chat",false);
        if(msgCount === 2){
            //insertMsg("","online",true);
            insertResponseMsgWithAutoDelay("","onlinev2",true);
            // insertResponseMsgWithAutoDelay("电话咨询满意后再付费，请放心咨询","chat",true);
        }else if(msgCount === 1){
            insertResponseMsgWithAutoDelay("","online",true);
        }else if(msgCount === 0){
            insertResponseMsgWithAutoDelay("","choice",true);
        }
    }
    const onBtnEvent = (eventType)=>{
        insertResponseMsgWithAutoDelay("","choice" )
    }
    const msgs = MsgList.map((record, idx) => {

        if (record.msgType === "chat") {
            return <MessageItem msg={record.content} senderByMe={record.senderByMe}  key={idx}></MessageItem>;
        }else if (record.msgType === "online") {
            return <MessageBuyPack key={idx}></MessageBuyPack>;
        }else if(record.msgType === "onlinev2"){
            return <MessageBuyPackV2 key={idx}></MessageBuyPackV2>
        }else{
            return <MessageChoice onMsgContinue={showBuy} key={idx}></MessageChoice>;
        }
      });
      const goback= ()=>{
        history.goBack();
      }
    return(
         <div className="container" >
            <div>
                <div style={{float:"right",marginTop:"6px",position:"absolute",right:"15px",fontWeight:"bold",fontSize:"18px"}}><CloseOutlined onClick={closeDraw}></CloseOutlined></div>
                <div
                 style={{fontWeight:"500",fontSize:"18px",textAlign:"center",height:"40px"}}>
                     <div id="inputing-normal" style={{display:"flex",alignItems:"center",justifyContent:"center",height:"100%"}}>法律顾问在线为你服务</div>
                </div>
              
            </div> 
            <div className="content" style={{overflowY:"scroll",paddingBottom:"14px"}} id="scrollable-root">
                <SecurityHint msg={"本次沟通已加密，请放心输入您的问题"}></SecurityHint>
               {msgs}
            </div>
            <div className="footer">
                <FooterBarUC onSendMsg={sendMsg} onBtnEvent={onBtnEvent}></FooterBarUC>
            </div>
            <input type="text" id="wechat-copy-input" style={{height:"2px",width:"10px",position:"absolute",opacity:"0"}}  value={wechat} onChange={(e)=>{}}></input >
        </div>
    // </div>
    );
}