import {React,useEffect} from "react";
import {PayResult} from './components/pay-result';
import { LawBook } from "./components/lawbook";
import {
  BrowserRouter  ,
  HashRouter,
  Route,
  Switch,
  useHistory
} from "react-router-dom";

import {Home} from './components/home';
import {HomeUC} from './components/home-uc';
import {NewHome} from './components/newhome';
import {Pay} from './components/pay';
import { PreBuy } from "./components/prebuy";
import { Lawsuit } from "./components/lawsuit";
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import './routers.css';
import 'antd/dist/antd.css';

export const RouterConfig=() =>{
  let history = useHistory();

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/home" component={NewHome}></Route>
        <Route exact path="/index" component={HomeUC}></Route>
        <Route exact path="/" component={NewHome}></Route>
        <Route exact path="/pay"  component={Pay}></Route>
        <Route exact path="/payresult" component={PayResult}></Route>
        <Route exact path="/prebuy" component={PreBuy}></Route>
        <Route exact path ="/lawsuit" component={Lawsuit}></Route>
        <Route exact path="/lawbook" component={LawBook}></Route>
      </Switch>
    </HashRouter>
  );
}

